import React, { useEffect, useRef } from 'react';
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import TopNav from 'components/Navbars/TopNav';
import Navbar from 'components/Navbars/SaasNav';
import Header from 'components/ITCreative/Header';
// import Header from 'components/Saas/Header';
import Community from 'components/Saas/Community';
import About from 'components/Saas/About';
// import Clients from 'components/Saas/Clients';
import Clients from 'components/Startup/Clients';
import Features from 'components/Saas/Features';
import Pricing from 'components/Saas/Pricing';
import Testimonials from 'components/Saas/Testimonials';
import Download from 'components/Saas/Download';
import Footer from 'components/Saas/Footer';

const HomeSaasTechnology = () => {
  const navbarRef = useRef(null);

   // Function to scroll to the target section based on the hash
   const scrollToSection = (hash) => {
    const id = hash?.substring(1); // Remove the '#' from the hash
    const targetSection = document.getElementById(id);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  useEffect(() => {
    // A helper function to ensure scrolling after a small delay
    const scrollWithDelay = () => {
      if (window.location.hash) {
        setTimeout(() => {
          scrollToSection(window.location.hash);
        }, 200); // Timeout to allow page render to complete
      }
    };

    // Call the scroll function on initial load
    scrollWithDelay();

    // Listen for hash changes and scroll to the section
    const handleHashChange = () => {
      scrollWithDelay();
    };

    // Attach the event listener for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <MainLayout>
      <TopNav style="5" />
      <Navbar navbarRef={navbarRef} />
      <Header />
      <main>
        <Community />
        <About />
        <Clients />
        {/* <Features /> */}
        <section id="features">
          <Features />
        </section>
        <section id="Pricing">
        <Pricing />
        </section>
        <Testimonials />
        <Download />
      </main>
      <Footer />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
      <title>Woxox - Future CRM</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  )
}

export default HomeSaasTechnology;